<template>
  <circle-loader v-if="isLoading" />
  <div class="procure" v-else>
    <div class="btn_header_wrapper" v-if="!isLoading">
      <div class="button">
        <router-link
          :to="{
            name: 'editShipment',
            params: { Id: id },
          }"
        >
          <button
            :disabled="item?.status.toLowerCase() !== 'logged'"
            class="button_btn"
            @mouseenter="handleMouseEnter(item?.status.toLowerCase())"
            @mouseleave="handleMouseLeave"
          >
            Edit Shipment
          </button>
        </router-link>
        <div
          v-if="showTooltip && item?.status.toLowerCase() !== 'logged'"
          class="tooltip"
        >
          {{ tooltipMessage }}
        </div>
      </div>
    </div>
    <div class="procure_inner">
      <div class="procure_inner_header">
        <h1>Item Name : {{ item?.name }}</h1>
        <!-- <div @click="openDeleteModal">
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1_2581)">
              <path
                d="M17 4H22V6H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V6H2V4H7V2H17V4ZM9 9V17H11V9H9ZM13 9V17H15V9H13Z"
                fill="#3E414C"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_2581">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div> -->
      </div>

      <div class="item_details">
        <!-- <div>
          <p>Supplier tracking number</p>
          <p>{{ item?.supplierTrackingNumber }}</p>
        </div> -->
        <div>
          <p>Tracking Number</p>
          <p>{{ item?.trackingNumber }}</p>
        </div>

        <div>
          <p>Weight</p>
          <p>{{ item?.weight }}{{ item?.weightUnit }}</p>
        </div>

        <div v-if="item?.quantity">
          <p>Quantity</p>
          <p>{{ item?.quantity }}</p>
        </div>

        <!-- <div>
          <p>Goods category</p>
          <p>{{ shipmentCategory?.title }}</p>
        </div> -->

        <div>
          <p>Freight Type</p>
          <p>{{ item?.freightType }}</p>
        </div>

        <div>
          <p>Pick Up Address</p>
          <p>{{ originPickupAddress?.unstructuredAddress }}</p>
        </div>

        <div>
          <p>Shipment Country</p>
          <p>{{ originPickupAddress?.country }}</p>
        </div>

        <div>
          <p>Drop off Address</p>
          <p>{{ destinationAddress?.unstructuredAddress }}</p>
        </div>

        <div>
          <p>Destination Country</p>
          <p>{{ destinationAddress?.country }}</p>
        </div>

        <div>
          <p>Status</p>
          <p>{{ item?.status }}</p>
        </div>
      </div>

      <div
        class="shipment_uploaded"
        v-if="item?.status.toLowerCase() !== 'logged'"
      >
        <div class="shipment_uploaded_image">
          <div v-if="item?.image" class="shipment_uploaded_image_wrapper">
            <img :src="this.isImageAvailable" alt="shipment item" />
          </div>

          <div
            class="btn_wrapper"
            v-if="item?.status.toLowerCase() === 'pending'"
          >
            <div class="button">
              <button @click="submitShipmentEdit" class="button_btn">
                Pay ₦{{ Number(this.priceOfGood).toLocaleString() }} for
                shipment
              </button>
            </div>
          </div>
        </div>

        <div class="items_timeline">
          <h1>Timeline</h1>

          <div>
            <div class="items_timeline_time">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11"
                  cy="11"
                  r="10.5"
                  :fill="getFillColor('LOGGED')"
                  stroke="#7EBAD2"
                />
              </svg>
              <p>Logged</p>
            </div>

            <div class="items_timeline_gap"></div>

            <div class="items_timeline_time">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11"
                  cy="11"
                  r="10.5"
                  :fill="getFillColor('PENDING')"
                  stroke="#7EBAD2"
                />
              </svg>
              <p>Pending</p>
            </div>

            <div class="items_timeline_gap"></div>

            <div class="items_timeline_time">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11"
                  cy="11"
                  r="10.5"
                  :fill="getFillColor('RECEIVED')"
                  stroke="#7EBAD2"
                />
              </svg>
              <p>Received</p>
            </div>

            <div class="items_timeline_gap"></div>

            <div class="items_timeline_time">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11"
                  cy="11"
                  r="10.5"
                  :fill="getFillColor('SHIPPED')"
                  stroke="#7EBAD2"
                />
              </svg>
              <p>Shipped</p>
            </div>

            <div class="items_timeline_gap"></div>

            <div class="items_timeline_time">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11"
                  cy="11"
                  r="10.5"
                  :fill="getFillColor('AWAITING_DELIVERY')"
                  stroke="#7EBAD2"
                />
              </svg>
              <p>Awaiting Delivery</p>
            </div>

            <div class="items_timeline_gap"></div>

            <div class="items_timeline_time">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11"
                  cy="11"
                  r="10.5"
                  :fill="getFillColor('DELIVERED')"
                  stroke="#7EBAD2"
                />
              </svg>

              <p>Delivered</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="btn_wrapper" @click="downloadPdf">
        <div class="button">
          <button class="button_btn">Download Invoice</button>
        </div>
      </div> -->
    </div>
  </div>
  <delete-modal
    :visible="showModal"
    @cancel="handleCancel"
    @confirm="handleConfirm"
  />
</template>

<script>
import CircleLoader from "../../components/loader/CircleLoader.vue";
import DeleteModal from "../../components/cards/DeleteModal.vue";
import Shipment from "@/utils/api/shipment/ShipmentApi";

export default {
  data() {
    return {
      isLoading: true,
      item: null,
      shipmentCategory: [],
      destinationAddress: [],
      originPickupAddress: [],
      statusOfOrder: [],
      priceOfGood: "",
      id: "",
      showModal: false,
      isImageAvailable: false,
      showTooltip: false,
      tooltipMessage:
        "This shipment order is already logged and cannot be edited. For any changes, please contact us at +2347037067376.",
    };
  },
  components: {
    CircleLoader,
    DeleteModal,
  },
  mounted() {
    this.fetchUserShipment();
  },
  methods: {
    handleMouseEnter(status) {
      if (status !== "logged") {
        this.showTooltip = true;
      }
    },
    handleMouseLeave() {
      this.showTooltip = false;
    },
    openDeleteModal() {
      this.showModal = true;
    },
    handleCancel() {
      this.showModal = false;
    },
    handleConfirm() {
      // Handle the delete action here
      console.log("Item deleted:");
      this.showModal = false;
      // Implement further delete logic here
    },
    async submitShipmentEdit() {
      const token = this.$store.state.tokens.accessToken;

      const shipmentId = this.$route.params.itemId;

      try {
        const shipmentPaymentRes = await Shipment.shipMentPayment({
          shipmentId,
          access_token: token,
        });

        // Open the payment gateway in another tab
        window.open(shipmentPaymentRes.data.data.authorization_url, "_blank");

        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.$toast.error(err.response?.data?.message || err.message);
      }
    },
    async fetchUserShipment() {
      const token = this.$store.state.tokens.accessToken;

      const shipmentId = this.$route.params.itemId;

      this.id = this.$route.params.itemId;

      this.isLoading = true;
      try {
        const userShipmentRes = await Shipment.getSingleShipment({
          shipmentId,
          access_token: token,
        });

        this.isImageAvailable =
          userShipmentRes.data.data[0].image !== null
            ? userShipmentRes.data.data[0].image
            : "";

        this.priceOfGood = userShipmentRes.data.data[0].price;

        // get shipment categories and filter to what is gotten from backend
        const shipmentCategories = await Shipment.getCategory();

        this.shipmentCategory = shipmentCategories.data.data.find(
          (category) => category._id === userShipmentRes.data.data[0].category
        );

        // get warehouses and filter to what is gotten from backend
        const getWarehouses = await Shipment.getWarehouse();

        this.originPickupAddress = getWarehouses.data.data.find(
          (location) =>
            location._id === userShipmentRes.data.data[0].pickUpWareHouseAddress
        );

        this.destinationAddress = getWarehouses.data.data.find(
          (location) =>
            location._id ===
            userShipmentRes.data.data[0].dropOffwarehouseAddress
        );

        // Check for the current status of the shipment
        const status = userShipmentRes.data.data[0].status;
        this.statusOfOrder = this.computeStatusOfOrder(status);

        this.isLoading = false;
        this.item = userShipmentRes.data.data[0];
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(err.response?.data?.message || err.message);
        }
      }
    },

    computeStatusOfOrder(status) {
      switch (status) {
        case "PENDING":
          return ["PENDING"];
        case "RECEIVED":
          return ["PENDING", "RECEIVED"];
        case "SHIPPED":
          return ["PENDING", "RECEIVED", "SHIPPED"];
        case "AWAITING_DELIVERY":
          return ["PENDING", "RECEIVED", "SHIPPED", "AWAITING_DELIVERY"];
        case "DELIVERED":
          return ["PENDING", "RECEIVED", "SHIPPED", "DELIVERED", "DELIVERED"];
        // case "DELIVERED":
        //   return [
        //     "PENDING",
        //     "RECEIVED",
        //     "SHIPPED",
        //     "DELIVERED",
        //     "DELIVERED",
        //     "DELIVERED",
        //   ];
        default:
          return [];
      }
    },

    getFillColor(status) {
      // Check if the status is included in the statusOfOrder array
      return this.statusOfOrder.includes(status) ? "#7EBAD2" : "#FAFAFA";
    },

    async downloadPdf() {
      const token = this.$store.state.tokens.accessToken;

      const shipmentId = this.$route.params.itemId;

      this.isLoading = true;
      try {
        const userSupplierPaymentRes = await Shipment.printInvoice({
          shipmentId: shipmentId,
          access_token: token,
        });

        this.isLoading = false;
        console.log(userSupplierPaymentRes.data);
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data?.message || err.response?.data?.error
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.procure {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.25rem;
  padding: 2.75rem 5.125rem;

  @media only screen and (max-width: 920px) {
    padding: 0rem 0.5em;
  }

  .btn_header_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 3.125em;

    @media only screen and (max-width: 920px) {
      width: 100%;
    }

    .button {
      position: relative;
      display: inline-block;
      .tooltip {
        background: #626262;
        color: #fafafa;
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        width: 200px;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 5px;
      }

      .tooltip::after {
        content: "";
        position: absolute;
        top: -5px;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #fafafa transparent;
      }
      @media only screen and (max-width: 920px) {
        width: 100%;
      }
      a {
        text-decoration: none;

        @media only screen and (max-width: 920px) {
          margin-top: 0.75rem;
          width: 100%;
        }

        button {
          width: 12.875em;

          @media only screen and (max-width: 920px) {
            width: 100%;
          }
        }
      }
    }
  }

  .procure_inner {
    display: flex;
    padding: 0.875rem 5.125rem;
    flex: 1;
    flex-direction: column;
    height: 60.3125em;
    border-radius: 1.875rem;
    gap: 2.8125rem;
    border: 1px solid #dedce9;
    background: #fafafa;

    @media only screen and (max-width: 1280px) {
      max-width: 770px;
      height: 130vh;
    }

    @media only screen and (max-width: 920px) {
      overflow: visible;
      gap: 1.5625rem;
      padding: 0rem 0.5em;
    }

    .procure_inner_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        cursor: pointer;
      }

      @media only screen and (max-width: 920px) {
        padding: 0.8rem 0em;
      }
      h1 {
        color: #000;
        font-family: $primary-font;
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .item_details {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5625rem;
      width: 40.8125em;

      @media only screen and (max-width: 920px) {
        width: 100%;
      }

      div {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p:nth-child(1) {
          color: #626262;
          font-family: $secondary-font;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.9375rem;
          }
        }

        p:nth-child(2) {
          flex: 1;
          max-width: 50%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #1d1d1f;
          font-family: $secondary-font;
          font-size: 0.9375rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.75rem;
          }
        }

        a {
          flex: 1;
          max-width: 50%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #2d5795;
          font-family: $secondary-font;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.75rem;
          }
        }
      }
    }

    .items_timeline {
      width: 100%;
      display: flex;
      gap: 2.0625rem;
      flex-direction: column;

      h1 {
        color: #000;
        font-family: $primary-font;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        margin: 0px;
        line-height: normal;
      }

      div {
        display: flex;
        width: 750px;

        @media only screen and (max-width: 1280px) {
          width: 100%;
        }

        .items_timeline_time {
          display: flex;
          gap: 0.4375rem;
          flex-direction: column;
          align-items: center;
          width: 6.25em;

          p:nth-child(2) {
            color: #626262;
            font-family: $secondary-font;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            @media only screen and (max-width: 920px) {
              font-size: 0.75rem;
            }
          }

          p:nth-child(3) {
            color: #626262;
            font-family: $secondary-font;
            font-size: 0.75rem;
            text-align: center;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media only screen and (max-width: 920px) {
              font-size: 0.625rem;
            }
          }
        }

        .items_timeline_gap {
          width: 7.875em;
          height: 0.9375em;
          border-bottom: 0.125rem dotted #626262;
        }
      }
    }

    .shipment_uploaded {
      display: flex;
      flex-direction: column;
      gap: 1.375rem;

      .shipment_uploaded_image {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 200px;
        overflow: hidden;
        border-radius: 5px;

        @media only screen and (max-width: 920px) {
          width: 350px;
          height: 300px;
          gap: 85px;
          flex-direction: column;
        }

        .shipment_uploaded_image_wrapper {
          display: flex;
          flex-direction: column;
          width: 594px;
          height: 200px;

          @media only screen and (max-width: 920px) {
            width: 250px;
            height: 150px;
          }

          img {
            width: 100%;
            height: 200px;
            display: block;
            object-fit: contain;

            @media only screen and (max-width: 920px) {
              height: auto;
            }
          }
        }
        .btn_wrapper {
          display: flex;
          padding: 23px, 248px, 23px, 243px;

          button {
            min-width: 12.875em;
            padding: 0.5em 1em;
            white-space: nowrap;
            text-align: center;

            @media only screen and (max-width: 920px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
