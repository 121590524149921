<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="booking">
    <div class="booking_inner">
      <h1>Enter Supplier's Details</h1>

      <form class="booking_form" @submit.prevent="supplierPaymentHandler">
        <div class="shipments_select">
          <label>Currency</label>
          <div class="shipments_select_wrapper">
            <select
              required
              v-model="currency"
              :items="currencyOptions"
              class="input_field"
            >
              <option value="">Select currency</option>
              <option
                v-for="option in currencyOptions"
                :key="option?._id"
                :value="option"
              >
                {{ option?.currency }}
              </option>
            </select>
          </div>
        </div>

        <div class="shipments_select">
          <label>Payment Method</label>
          <div class="shipments_select_wrapper">
            <select
              required
              :items="paymentOptions"
              v-model="paymentMethod"
              class="input_field"
            >
              <option value="">Select payment method</option>
              <option
                v-for="option in paymentOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="shipments_select">
          <label
            >Amount <span>{{ exchangeRate ? exchangeRate : "" }}</span>
          </label>
          <div class="input">
            <input
              v-model="formattedAmount"
              @input="formatAmount"
              required
              type="text"
              placeholder="Enter amount"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select" v-if="paymentMethod !== 'ALIPAY'">
          <label>Bank Name</label>
          <div class="input">
            <input
              v-model="bank_name"
              required
              type="text"
              placeholder="Enter bank name"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select" v-if="paymentMethod !== 'ALIPAY'">
          <label>Supplier Account Name</label>
          <div class="input">
            <input
              v-model="account_name"
              required
              type="text"
              placeholder="Enter account name"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select" v-if="paymentMethod === 'WIRE'">
          <label>Fedwire Routing Number</label>
          <div class="input">
            <input
              v-model="fedwireRoutingNumber"
              required
              type="number"
              placeholder="Fedwire routing number"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select" v-if="paymentMethod === 'ACH'">
          <label>ACH Routing Number</label>
          <div class="input">
            <input
              v-model="routingNumber"
              required
              type="number"
              placeholder="ACH routing number"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select" v-if="paymentMethod === 'SWIFT'">
          <label>SWIFT / BIC code</label>
          <div class="input">
            <input
              v-model="bicCode"
              required
              type="number"
              placeholder="Enter SWIFT / BIC code"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select" v-if="paymentMethod !== 'ALIPAY'">
          <label
            >{{ paymentMethod === "SWIFT" ? "IBAN / " : "" }}Account
            Number</label
          >
          <div class="input">
            <input
              v-model="account_number"
              required
              type="text"
              :placeholder="
                paymentMethod === 'SWIFT'
                  ? 'Enter IBAN / Account number'
                  : 'Enter account number'
              "
              class="input_field"
            />
          </div>
        </div>

        <div
          class="shipments_select"
          v-if="
            paymentMethod === 'Bank Transfer' &&
            currency.currency === 'Great Britain Pound'
          "
        >
          <label>UK Sort Code</label>
          <div class="input">
            <input
              v-model="ukSortCode"
              required
              type="text"
              placeholder="Enter sort code"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select" v-if="paymentMethod === 'ACH'">
          <label>Account Type</label>
          <div class="shipments_select_wrapper">
            <select required v-model="account_type" class="input_field">
              <option value="">Account Type</option>
              <option value="checking">Checking</option>
              <option value="savings">Savings</option>
            </select>
          </div>
        </div>

        <div class="shipments_select" v-if="paymentMethod === 'ALIPAY'">
          <label>Supplier Name</label>
          <div class="input">
            <input
              v-model="account_name"
              required
              type="text"
              placeholder="Enter name"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select" v-if="paymentMethod === 'ALIPAY'">
          <label>Alipay Number</label>
          <div class="input">
            <input
              v-model="alipayNumber"
              required
              type="number"
              placeholder="Enter alipay number"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Supplier Address</label>
          <div class="input">
            <input
              v-model="address"
              required
              type="text"
              placeholder="Enter supplier address"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Supplier City</label>
          <div class="input">
            <input
              v-model="city"
              required
              type="text"
              placeholder="Enter supplier city"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select" v-if="paymentMethod !== 'ALIPAY'">
          <label>Postcode</label>
          <div class="input">
            <input
              v-model="postCode"
              required
              type="text"
              placeholder="Enter postcode"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Country</label>
          <div class="shipments_select_wrapper">
            <select
              required
              v-model="country"
              :items="countries"
              class="input_field"
            >
              <option value="">Supplier country</option>
              <option
                v-for="country in countries"
                :key="country.code"
                :value="country.name"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="shipments_select">
          <label>Supplier Email Address</label>
          <div class="input">
            <input
              v-model="emailAddress"
              required
              type="text"
              placeholder="Enter supplier email address"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select" v-if="paymentMethod !== 'ALIPAY'">
          <label>Supplier Contact Number</label>
          <div class="input">
            <input
              v-model="contact_number"
              required
              type="text"
              placeholder="Enter contact number"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Attach Invoice (pdf)</label>
          <div class="input">
            <input
              required
              type="file"
              accept="application/pdf"
              @change="handleFileUpload"
              placeholder="Enter company name"
              class="input_field"
            />
          </div>
        </div>

        <div v-if="!isLoading" class="button">
          <button class="button_btn">Submit</button>
        </div>

        <div v-else class="button">
          <button :disabled="isLoading" class="button_btn">
            <div class="loading-circle"></div>
          </button>
        </div>
      </form>
    </div>
  </div>
  <Overlay
    :amountToPayInNaira="amountToPayInNaira"
    :payment_id="payment_id"
    @closeModal="modalOpen = false"
    :openModal="modalOpen"
  />
</template>

<script>
import Overlay from "../../components/cards/Overlay.vue";
import Countries from "../../utils/Countries";
import SupplierPayments from "../../utils/api/supplierPayments/SupplierPayments";
export default {
  data() {
    return {
      modalOpen: false,
      city: "",
      country: "",
      address: "",
      emailAddress: "",
      postCode: "",
      countries: [],
      ukSortCode: "",
      contact_number: "",
      alipayNumber: "",
      payment_id: "",
      paymentMethod: "",
      amountToPayInNaira: 0,
      account_name: "",
      bank_name: "",
      account_number: "",
      amount: "",
      invoice: "",
      routingNumber: "",
      account_type: "",
      currencyOptions: [],
      fedwireRoutingNumber: "",
      currency: "",
      isLoading: false,
      paymentOptions: [],
      bicCode: "",
      exchangeRate: "",
    };
  },
  components: {
    Overlay,
  },
  mounted() {
    this.fetchAllCurrencies();
    this.getCountries();
  },

  watch: {
    currency(newValue) {
      if (
        newValue.currency === "Great Britain Pound" ||
        newValue.currency === "Great Britain Pounds"
      ) {
        this.country = "United Kingdom";
        this.exchangeRate = `${newValue.amount}/£`;
        this.paymentOptions = ["Bank Transfer"];
      } else if (
        newValue.currency === "United States Dollars" ||
        newValue.currency === "United States Dollar"
      ) {
        this.exchangeRate = `${newValue.amount}/$`;
        this.country = "";
        this.paymentOptions = ["ACH", "WIRE", "SWIFT"];
      } else {
        this.exchangeRate = `${newValue.amount}/¥`;
        this.country = "China";
        this.paymentOptions = ["Bank Transfer", "ALIPAY"];
      }
    },
  },

  computed: {
    formattedAmount: {
      get() {
        return this.amount;
      },
      set(value) {
        this.amount = value;
      },
    },
  },

  methods: {
    formatAmount(event) {
      let value = event.target.value;
      let parts = value.split(".");
      let v = parts[0].replace(/\D/g, "");
      let dec = parts[1];
      let formattedNumber = new Intl.NumberFormat("en-EN").format(v);
      formattedNumber =
        dec !== undefined ? formattedNumber + "." + dec : formattedNumber;

      this.amount = formattedNumber;
    },
    convertToNumber(amount) {
      let numericValue = amount.replace(/,/g, "");
      return parseFloat(numericValue);
    },
    getCountries() {
      this.countries = Countries;
    },
    async fetchAllCurrencies() {
      try {
        const currenciesRes = await SupplierPayments.getExchangeRates();
        this.currencyOptions = currenciesRes.data.data;
      } catch (err) {
        this.$toast.error(err.response?.data?.message || err.message);
      }
    },

    removeBase64Prefix(base64String) {
      // Check if the string starts with the prefix
      if (base64String.startsWith("data:application/pdf;base64,")) {
        // Remove the prefix from the string
        return base64String.slice("data:application/pdf;base64,".length);
      } else {
        // Return the original string if it doesn't start with the prefix
        return base64String;
      }
    },

    // Function to update and store the image url
    handleFileUpload(event) {
      const invoice_file = event.target.files;

      const pdfFile = invoice_file.item(0);

      const formdata = new FileReader();

      formdata.readAsDataURL(pdfFile);

      formdata.onload = (event) => {
        this.invoice = this.removeBase64Prefix(event.target.result);
      };
    },

    async supplierPaymentHandler() {
      const token = this.$store.state.tokens.accessToken;
      this.isLoading = true;
      try {
        const supplierRes = await SupplierPayments.createSupplierPayment({
          bankName: this.bank_name,
          access_token: token,
          accountNumber: this.account_number,
          amount: this.convertToNumber(this.amount),
          method: this.paymentMethod,
          currency: this.currency.currencyAbbreviation,
          companyName: this.company_name,
          invoiceFile: this.invoice,
          swiftBicCode: this.bicCode,
          alipayNumber: this.alipayNumber,
          accountName: this.account_name,
          address: this.address,
          city: this.city,
          country: this.country,
          postCode: this.postCode,
          supplierEmail: this.emailAddress,
          contactNumber: this.contact_number,
          routingNumber: this.routingNumber,
          fedWireRoutingNumber: this.fedwireRoutingNumber,
          sortCode: this.ukSortCode,
        });

        const paymentsData = supplierRes.data.data;

        this.amountToPayInNaira = paymentsData.amountinLocal;

        this.payment_id = paymentsData._id;

        this.isLoading = false;
        this.$toast.success("Request for supplier payment has been made.");
        this.modalOpen = true;
      } catch (err) {
        this.isLoading = false;
        console.log(err.response);
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data ||
              err.response?.data?.message ||
              err.response?.data?.error
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "vue-select/dist/vue-select.css";
@import "../../styles/variables.scss";
.booking {
  width: 100%;
  padding: 2.75rem 5.125em;

  @media only screen and (max-width: 920px) {
    padding: 0rem 0.5em;
  }

  .booking_inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 55.3125em;
    flex-shrink: 0;
    border-radius: 1.875rem;
    padding: 1.8125rem 0rem;
    gap: 2.8125rem;
    overflow-y: scroll;
    border: 0.0625rem solid #dedce9;
    background: #fafafa;
    align-items: center;

    @media only screen and (max-width: 920px) {
      overflow: visible;
      height: auto;
    }

    h1 {
      margin-top: 5px;
      color: #000;
      font-family: $primary-font;
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @media only screen and (max-width: 920px) {
        font-size: 1.5625rem;
        margin-top: 0.3125rem;
      }
    }

    .booking_form {
      display: flex;
      flex-direction: column;
      gap: 3.4375rem;
      width: 100%;
      align-items: center;

      .loading-circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #fff;
        position: relative;
        animation: rotate 1s linear infinite;
      }

      .loading-circle::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
        animation: bounce 0.6s linear infinite;
      }

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      @keyframes bounce {
        0%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(0);
        }
      }

      @media only screen and (max-width: 920px) {
        width: 100%;
        padding: 0rem 0.5rem;
      }

      .shipments_select {
        border-radius: 0.625rem;
        width: 29.1875em;
        height: 50px;
        gap: 0.625em;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 920px) {
          width: 100%;
        }

        .shipments_select_wrapper {
          padding: 0.3125rem 0.8125rem;
          width: 100%;
          height: 3.225em;
          display: flex;
          border: 0.0625rem solid #dedce9;
          border-radius: 0.625rem;
          background-color: #fff;

          select {
            border-radius: 0.625rem;
            width: 100%;
            height: 3.225em;
            background-color: #fff;

            color: #363539;
            border: none;
            border: 1px solid transparent;

            font-family: $secondary-font;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:focus {
              outline: none;
            }
          }
        }

        label {
          color: #606060;
          font-family: $secondary-font;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          span {
            color: rgb(255, 92, 92);
          }
        }
      }

      .button {
        width: 29.1875em;

        @media only screen and (max-width: 920px) {
          width: 100%;
        }
      }
    }
  }
}
</style>
